'use client';

import { useEffect, useState } from 'react';

import { Locale, type Nullable } from 'corgi-types';
import CommonDrawer from 'corgi/common-drawer/components/CommonDrawer';
import CommonVisualNavigation from 'corgi/common-visual-navigation/components/CommonVisualNavigation';
import { Flex } from 'corgi/common/components/Flex';
import { Grid, GridItem } from 'corgi/common/components/Grid';
import { CaptionRegularCR1 } from 'corgi/common/components/Typography';
import { Locale as CorgiLocale } from 'corgi/common/enums/Locale';
import { useAppDomain } from 'corgi/common/providers/AppDomainProvider';
import { useLocalization } from 'corgi/common/providers/LocaleProvider';
import { getTraceID } from 'corgi/common/utils/newrelic';
import GlobalHead from 'corgi/global-head/components/GlobalHead';
import type { GlobalNavigationProps } from 'frontend-utils';

import ErrorPageStyles from '@components/common/ErrorPageStyles';
import { LogoutBanner } from '@components/common/fragments/AutoLogoutBanner';
import Footer from '@components/common/fragments/Footer';
import Header from '@components/common/fragments/Header';
import errorStatic from '../static/error-page.json';
import visualNavigationStatic from '../static/visual-navigation.json';

const useLocale = () => {
  const { locale: corgiLocale } = useLocalization();
  return (corgiLocale === CorgiLocale.De_De_H24 ? Locale.DE_DE : corgiLocale) as Locale;
};

export default function ErrorPage() {
  const locale = useLocale();
  const appDomain = useAppDomain();
  const [hasError, setHasError] = useState(false);
  const [globalNavProps, setGlobalNavProps] = useState<Nullable<GlobalNavigationProps>>(null);

  useEffect(() => {
    if (globalNavProps) {
      return;
    }

    import(`../static/global-navigation/${locale}.json`)
      .then(data => setGlobalNavProps(data))
      .catch(() => setHasError(true));
  }, [globalNavProps, locale]);

  const { title, subTitle } = errorStatic[locale];
  const visualNavigationProps = visualNavigationStatic[locale];

  const traceID = document && getTraceID();

  if (!globalNavProps) {
    return hasError ? 'Internal server error' : null;
  }

  return (
    <>
      <GlobalHead baseUrl={appDomain} />

      <LogoutBanner />
      <Header {...globalNavProps} />
      <CommonDrawer />

      <ErrorPageStyles />
      <section id="error">
        <h1>{title}</h1>
        <p>{subTitle}</p>
        {traceID && (
          <Flex mt="sp_24" justifyContent="center">
            <CaptionRegularCR1>{traceID}</CaptionRegularCR1>
          </Flex>
        )}
      </section>

      <Grid>
        <GridItem>
          <CommonVisualNavigation {...visualNavigationProps} />
        </GridItem>
      </Grid>

      <Footer {...globalNavProps} />
    </>
  );
}
