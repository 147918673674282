'use client';

import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';

import type { GrowthBookPayload, TrackingData } from '@growthbook/growthbook';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbookTrackingCallback } from 'corgi/common/tracking/growthbook';

export type GrowthBookClientProps = PropsWithChildren<{ payload: GrowthBookPayload }> & {
  attributes: object;
  enableDevMode: boolean;
};

export const GrowthBookClient = ({
  payload,
  children,
  attributes,
  enableDevMode,
}: GrowthBookClientProps) => {
  const gb = useMemo(
    () =>
      new GrowthBook({
        apiHost: process.env.GROWTHBOOK_API_HOST,
        clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
        attributes,
        enableDevMode,
        trackingCallback: growthbookTrackingCallback,
      }).initSync({
        payload,
      }),
    [payload, attributes, enableDevMode],
  );

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};

export const GrowthBookTracking = ({ data }: { data: TrackingData[] }) => {
  useEffect(() => {
    data.forEach(({ experiment, result }) => growthbookTrackingCallback(experiment, result));
  }, [data]);

  return null;
};
