import React from 'react';

import { PageGridItem } from 'corgi/common/components/PageGrid';

import type { PageSliceConfig } from '@local-types/content/layout';
import ComponentSlice from './ComponentSlice';
import ContentPlaceholderSlice from './ContentPlaceholderSlice';
import LayoutSlice from './LayoutSlice';

const getSlice = (sliceConfig: PageSliceConfig) => {
  if ('corgiType' in sliceConfig) {
    return <ComponentSlice sliceConfig={sliceConfig} />;
  }

  if ('layoutType' in sliceConfig) {
    return <LayoutSlice sliceConfig={sliceConfig} />;
  }

  if ('contentPlaceholder' in sliceConfig) {
    return <ContentPlaceholderSlice sliceConfig={sliceConfig} />;
  }
};

const Slices = ({ slices }: { slices: PageSliceConfig[] }) => (
  <PageGridItem flexDirection="column" alignItems="stretch">
    {slices?.map(sliceConfig => getSlice(sliceConfig))}
  </PageGridItem>
);

export default Slices;
