import type { ContentPlaceholderSelector, MapOf } from 'corgi-types';

export const enum LayoutType {
  ShopTheLook = 'shop_the_look',
  VerticalSpacing = 'vertical_spacing',
}

export type LayoutProps = MapOf<string, object>;

export interface LayoutConfig {
  layoutType: LayoutType;
  props: LayoutProps;
}

export interface ComponentConfig {
  corgiType: string;
  props: object;
}

export interface ContentPlaceholderConfig {
  contentPlaceholder: true;
  props: {
    contentPlaceholderSelector: ContentPlaceholderSelector;
  };
}

export type PageSliceConfig = ComponentConfig | LayoutConfig | ContentPlaceholderConfig;
