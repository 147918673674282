import React from 'react';

import { PageGridItem } from 'corgi/common/components/PageGrid';
import PictureText from 'corgi/picture-text/components/PictureText';
import RecommendationsSlider from 'corgi/recommendations-slider/components/RecommendationsSlider';

import type { LayoutProps } from '@local-types/content/layout';
import { arePropsNonEmpty } from '@utils/render';
import { getSliceStyles } from '../Slices/styles';

const ShopTheLook = ({ layoutProps }: { layoutProps: LayoutProps }) => {
  const pictureProps = layoutProps['picture-text'];
  const recoSliderProps = layoutProps['recommendations-slider'];

  const withRecoSlider = arePropsNonEmpty(recoSliderProps);

  return (
    <PageGridItem display="flex" flexDirection="column" width={1}>
      <PageGridItem
        width={1}
        {...(!withRecoSlider && { mb: ['sp_16', 'sp_20', 'sp_24', 'sp_32'] })}
      >
        <PictureText {...pictureProps} />
      </PageGridItem>
      <PageGridItem {...getSliceStyles('recommendations-slider')}>
        {withRecoSlider && <RecommendationsSlider {...recoSliderProps} />}
      </PageGridItem>
    </PageGridItem>
  );
};

export default ShopTheLook;
