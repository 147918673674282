import React from 'react';

import { ContentPlaceholderSelector } from 'corgi-types';
import CheckGiftCardBalanceForm from 'corgi/check-gift-card-balance-form/components/CheckGiftCardBalanceForm';
import { PageGridItem } from 'corgi/common/components/PageGrid';
import HomeClubSection from 'corgi/home-club-section/components/HomeClubSection';
import ReferAFriendSection from 'corgi/refer-a-friend-section/components/ReferAFriendSection';

import type { ContentPlaceholderConfig } from '@local-types/content/layout';
import Butlers from '../Layouts/Butlers';

const MARGIN_BOTTOM = ['sp_16', 'sp_20', 'sp_24', 'sp_32'];

const ContentPlaceholderSlice = ({ sliceConfig }: { sliceConfig: ContentPlaceholderConfig }) => {
  const { props } = sliceConfig;
  const { contentPlaceholderSelector, ...restProps } = props;

  switch (contentPlaceholderSelector) {
    case ContentPlaceholderSelector.Butlers: {
      return <Butlers />;
    }

    case ContentPlaceholderSelector.ReferAFriend: {
      return (
        <PageGridItem mb={MARGIN_BOTTOM}>
          <ReferAFriendSection {...restProps} />
        </PageGridItem>
      );
    }

    case ContentPlaceholderSelector.HomeClub: {
      return (
        <PageGridItem mb={MARGIN_BOTTOM}>
          <HomeClubSection {...restProps} />
        </PageGridItem>
      );
    }

    case ContentPlaceholderSelector.CheckGiftCardBalance: {
      return (
        <PageGridItem mb={MARGIN_BOTTOM}>
          <CheckGiftCardBalanceForm />
        </PageGridItem>
      );
    }

    default:
      return null;
  }
};

export default ContentPlaceholderSlice;
